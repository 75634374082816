import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'
import classNames from 'lib/classNames'
import Loading from 'components/Loading'

import './index.sass'

export default function BannerImage({
  className,
  defaultSrc,
  src,
  style = {},
  ...props
}){
  const [isLoading, setIsLoading] = useState(true)
  const [imgSrc, setImgSrc] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    const finalSrc = src || defaultSrc
    if (!finalSrc) {
      setIsLoading(false)
      return
    }

    const img = new Image()
    img.onload = () => {
      setImgSrc(finalSrc)
      setIsLoading(false)
    }
    img.onerror = () => {
      setIsLoading(false)
    }
    img.src = finalSrc
  }, [src, defaultSrc])

  style.backgroundImage = imgSrc ? `url(${imgSrc})` : ''

  return (
    <div {...{
      ...props,
      className: classNames('BannerImage', { className }),
      style,
    }}>
      {isLoading && (
        <div className="BannerImage-loader">
          <Loading size="md" type="block" />
        </div>
      )}
    </div>
  )
}

BannerImage.propTypes = {
  className: PropTypes.string,
  defaultSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
}
